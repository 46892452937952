import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { HomePage } from "../pages/home/HomePage";

export function DefaultRouter() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/* no match route */}
      {/*https://reactrouter.com/docs/en/v6/getting-started/tutorial#adding-a-no-match-route*/}
      <Route path="*" element={<Navigate replace to={"/"} />} />
    </Routes>
  );
}
