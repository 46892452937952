import React from "react";
import { Navbar } from "./components/Navbar/Navbar";
import { BrowserRouter } from "react-router-dom";
import { DefaultRouter } from "./infrastructure/Router";

function App() {
  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <br />
      <div className="container">
        <DefaultRouter />
      </div>
      <footer
        style={{
          padding: 12,
          backgroundColor: "#ECECEC",
        }}
        className="footer text-sm bottom text-center"
      >
        <span style={{ color: "rgba(0,0,0,.5)" }}>
          {"\u00A9"} Kunzler Equipamentos {new Date().getFullYear()}
        </span>
      </footer>
    </BrowserRouter>
  );
}

export default App;
