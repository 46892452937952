import React from "react";

export function HomePage() {
  return (
    <>
    <div style={{display: 'flex', flexDirection: "column", alignItems: 'center', width: "100%"}}>
      <img src={require("./img/logo.png")} style={{width: 400, marginBottom: "1rem"
      }} alt="logo" />
      <h1 style={{color: '#fafafa'}}>Site em Construção</h1>
      <h2 style={{color: '#fafafa'}}>Contatos</h2>
      <p style={{color: '#fafafa'}}>comercial@kunzlerequipamentos.com.br</p>
      <p style={{color: '#fafafa'}}>pecas@kunzlerequipamentos.com.br</p>
      <p style={{color: '#fafafa'}}>servicos@kunzlerequipamentos.com.br</p>
    </div>
    </>
  );
}
